import Swiper from 'swiper/bundle';

const bannerSelectors = document.querySelectorAll(".banner-selector");

bannerSelectors.forEach((bannerSelector) => {

    const bannerImageSlider = new Swiper(
        bannerSelector.querySelector(".banner-media-slider"),
        {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 30,
            effect: "fade",
            fadeEffect: { 
                crossFade: true 
            },
            autoplay: {
                delay: 2000,
            },
        }
    );
});
