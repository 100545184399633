import Swiper from 'swiper';
import {Navigation, Controller, EffectFade} from 'swiper/modules';



jQuery(document).ready(function() {
        
    if(document.querySelector(".hero-slider__swiper")) {

        const swiper2 = new Swiper('.hero-slider__swiper', {
            navigation: {
                prevEl: '.hero-slider__swiper-button-prev',
                nextEl: '.hero-slider__swiper-button-next',
            },
            modules: [Navigation, EffectFade, Controller],
            effect: "fade",
            loop:false,
            fadeEffect: {
                crossFade: true
            },
        });

        const swiper = new Swiper('.hero-slider-text__swiper', {
            spaceBetween: 0,
            modules: [Navigation, EffectFade, Controller],
            loop: false,
            effect: "slide",
        });

        // sync both sliders together
        swiper.controller.control = swiper2;
        swiper2.controller.control = swiper;

    }

});