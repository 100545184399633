import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';

document.querySelectorAll('.swiper-split-banner').forEach(slider => {
    new Swiper(slider, {
        modules: [Navigation, Pagination],
        spaceBetween: 0,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-banner-pagination',
            clickable: true
        },
    });
});
