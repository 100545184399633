import Swiper from "swiper";

import { Controller, Navigation, Thumbs } from "swiper/modules";

const mediaSliderSelectors = document.querySelectorAll(
    ".media-slider-selector"
);

mediaSliderSelectors.forEach((mediaSliderSelector) => {
    const thumbnailSlider = new Swiper(
        mediaSliderSelector.querySelector(".media-slider-thumbnails"),
        {
            modules: [Controller, Thumbs, Navigation],
            slidesPerView: 3,
            spaceBetween: 20,
            direction: "vertical",
            slidesPerGroup: 3,
            navigation: {
                nextEl: mediaSliderSelector.querySelector(
                    ".media-slider-thumbnails-next"
                ),
                prevEl: mediaSliderSelector.querySelector(
                    ".media-slider-thumbnails-prev"
                ),
            },
        }
    );

    // init image slider
    const imageSlider = new Swiper(
        mediaSliderSelector.querySelector(".media-slider-slider"),
        {
            modules: [Controller, Thumbs],
            loop: true,
            slidesPerView: 1,
            spaceBetween: 96,
            autoHeight: true,
            breakpoints: {
                1024: {
                    autoHeight: false,
                },
            },
            thumbs: {
                swiper: thumbnailSlider,
            },
        }
    );

    // init text slider
    const textSlider = new Swiper(
        mediaSliderSelector.querySelector(".media-slider-text"),
        {
            modules: [Controller, Thumbs],
            loop: true,

            autoHeight: true,
            slidesPerView: 1,
            spaceBetween: 96,
            thumbs: {
                swiper: thumbnailSlider,
            },
        }
    );

    // sync sliders
    imageSlider.controller.control = [thumbnailSlider, textSlider];
    textSlider.controller.control = [thumbnailSlider, imageSlider];
});
