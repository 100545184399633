import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

document.querySelectorAll('.swiper-slider-gallery').forEach(slider => {
    new Swiper(slider, {
        modules: [Navigation, Pagination],
        slidesPerView: 3,
        spaceBetween: 0,
        loop: true,
        navigation: {
            prevEl: '.swiper-slider-gallery-button-prev',
            nextEl: '.swiper-slider-gallery-button-next',
        },
        pagination: {
            el: '.swiper-slider-gallery-pagination',
            clickable: true
        },
    });
});

document.addEventListener('DOMContentLoaded', function () {

    document.querySelectorAll('.slider-gallery').forEach(gallery => {

        const lightbox = new PhotoSwipeLightbox({
            gallery: gallery,
            children: 'a',
            showHideAnimationType: 'zoom',
            pswpModule: () => import('photoswipe')
        });
        lightbox.init();

    });

});
