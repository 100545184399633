import PhotoSwipeLightbox from 'photoswipe/lightbox';

document.addEventListener('DOMContentLoaded', function () {

    document.querySelectorAll('.gallery').forEach(gallery => {

        const lightbox = new PhotoSwipeLightbox({
            gallery: gallery,
            children: 'a',
            showHideAnimationType: 'zoom',
            pswpModule: () => import('photoswipe')
        });
        lightbox.init();

    });

});
